footer {
  background: var(--color-footer);
  padding: 3rem 0;
  text-align: center;
  font-size: 1rem;
  margin-top: 7rem;
  margin-bottom: 5rem;
}

/* .footer-logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
} */

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
  font-size: 1.5rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 3rem;
  margin-bottom: 4rem;
}
.footer__socials a {
  background: white;
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 2px solid transparent;
}
.footer__socials a:hover {
  color: var(--color-footer);
}

.footer__copyright {
  margin-bottom: 4rem;
  font-size: 1.2rem;
}

/* =================== MEDIA QUERIES - 1024px */

@media screen and (max-width: 1024px) {
  .permalinks {
    flex-direction: column;
    gap: 1rem;
  }
}

/* =================== SMALL QUERIES - 600px */

@media screen and (max-width: 600px) {
  footer {
    width: 100%;
  }
  .permalinks {
    flex-direction: column;
    gap: 1rem;
  }
  .footer__socials {
    margin-bottom: 2.5rem;
    gap: 0.5rem;
  }
}
