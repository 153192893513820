* {
  --color-primary: #4db5ff;
}

header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* =========== CTA ============= */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
/* =========== HEADER SOCIALS ============= */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  font-size: 50px;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
}

.header__socials a {
  height: 2rem;
  width: 2rem;
  color: blueviolet;
}
/* =========== AVATAR (ME) ============= */

.me {
  /* background: var(--color-primary); */
  width: auto;
  height: auto;
  position: absolute;
  left: calc(50% - 25rem);
  margin-top: 4rem;
  margin-left: 5rem;
  border-radius: 5rem 5rem 0 0;
  overflow: hidden;
}

/* =========== SCROLL DOWN ============= */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-size: 2rem;
  font-weight: 500;
  color: rgb(137, 29, 199);
}

/* =================== MEDIA QUERIES - 1024px */

@media screen and (max-width: 1024px) {
  header {
    height: 80vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}

/* =================== SMALL QUERIES - 600px */

@media screen and (max-width: 600px) {
  header {
    height: 50vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
  .me img {
    display: none;
  }
}
